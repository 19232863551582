import "./tailwind.css";

import * as React from "react";
import { FunctionComponent } from "react";
import Header from "../components/header";
import Hero from "../components/hero";
import HandActionItem from "../components/hand-action-item";
import Partners from "../components/partners";
import Dashboard1 from "../components/dashboard1";
import Dashboard2 from "../components/dashboard2";
import PlatformGrid from "../components/platform-grid";
import Integrations from "../components/integrations";
import Slider from "../components/slider";
import GetStartedBanner from "../components/get-started-banner";
import Footer from "../components/footer";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

import { Box, Flex } from "@chakra-ui/react";
import ContactUsModal from "../components/contact-us-modal";
import { motion } from "framer-motion";
import ShowcaseVideo from "../components/showcase-video";
import { useMixpanel } from "gatsby-plugin-mixpanel";
// import Dashboard from "../components/dashboard";

const LandingPage: FunctionComponent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box className="relative overflow-clip bg-gray-100 w-full flex flex-col p-[16px_16px_0px] box-border items-center justify-center text-left text-2xl text-gray-800 font-helvetica-neue">
      <Header contactUs={onOpen} />
      <div className="flex flex-col items-center justify-start gap-[108px]">
        <Hero />
        <Flex
          sx={{
            "@media screen and (max-width: 820px)": {
              flexDir: "column",
              gap: "64px",
            },
          }}
          direction="row"
          className="flex max-w-[1060px] w-full mb-[140px] items-center justify-between"
        >
          <HandActionItem
            image="../illustration--fees.svg"
            title="Less fees, less headaches"
            title1="Get a concrete fees plan. "
            title2="Forget about paying more than 1% "
          />
          <HandActionItem
            image="../illustration--pay-with-click.svg"
            title="Pay with one click"
            title1="Boost conversions by eliminating"
            title2="all the extra steps"
          />
          <HandActionItem
            image="../illustration--settle-instantly.svg"
            title="Settle instantly"
            title1="Get money straight into"
            title2="your bank account "
          />
        </Flex>
      </div>
      <Box
        gap="160px"
        sx={{
          "@media screen and (max-width: 800px)": {
            gap: "89px",
          },
        }}
        className="self-stretch flex flex-col p-[0px_0px_38px] box-border items-center justify-start"
      >
        <ShowcaseVideo />
        <Partners />
        {/* <Dashboard isReversed={false} dashboardImagePath="../dashboard1.svg" /> */}
        {/* <Dashboard isReversed={true} dashboardImagePath="../dashboard1.svg" /> */}
        <PlatformGrid />
        <Integrations />
        <Slider />
        <GetStartedBanner />
      </Box>
      <Footer contactUs={onOpen} />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ContactUsModal />
      </Modal>
    </Box>
  );
};

export default LandingPage;

/*
TODO: fix fonts loading
*/
export function Head() {
  return (
    <>
      <title>Enbank</title>

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color="#5bbad5"
      />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />

      <meta property="og:title" content="Enbank - #NoCardsOnline" />
      <meta
        property="og:description"
        content="The future of payments simplified 🚀"
      />
      <meta property="og:image" content="https://enbank.me/heading-image.png" />
      <meta name="twitter:image" content="https://enbank.me/heading-image.png" />
      <meta property="description" content="Enbank is a payment processing company that offers account-to-account payment solutions with payment processing fees of less than 1% per transaction, one-click payment experience, and payment straight into your bank account within one day. Simplify your payments with Enbank." />
      <meta property="keywords" content="enbank, payment processing, account-to-account payment, payment solutions, payment processing fees, less than 1%, one-click payment, payment experience, bank account, simplify payments" />
      <meta property="image" content="https://enbank.me/heading-image.png" />
      <meta name="twitter:creator" content="@enbank" />
    </>
  );
}
