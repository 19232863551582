import { Box, Flex, Image, Link, Text } from "@chakra-ui/react";
import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { FunctionComponent } from "react";

const HeroImage: FunctionComponent = () => {
  const query = useStaticQuery(graphql`
    query HeroQuery {
      file(base: { eq: "hero-illustration.svg" }) {
        svg {
          originalContent
        }
      }
    }
  `);
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: query.file.svg.originalContent }}
      mt="101px"
      sx={{
        "@media screen and (max-width: 1080px)": {
          h: "100%",
        },
      }}
      className="relative shrink-0 w-[565px]"
      pb="44px"
    />
  );
};

const Hero = () => {
  return (
    <Flex
      overflow="hidden"
      className="relative flex lg:left-[-80px] xl:left-[0px] lg:flex-row items-center flex-col w-[100vw] max-w-[1440px] text-left text-xs text-gray-500 font-inter"
    >
      <HeroImage />

      <div className="relative w-full">
        <Flex
          maxW="492px"
          w="100vw"
          boxSizing="border-box"
          p="8px"
          className="lg:mt-[126px] mx-auto w-fit flex flex-col items-start justify-start gap-[32px]"
        >
          {/* <div className="flex flex-row items-center justify-start gap-[32px] text-center"> */}
          {/* <div className="flex flex-row items-center justify-start gap-[12px]">
              <img
                className="relative w-[8px] h-[8px] shrink-0"
                alt=""
                src="../ellipse-6571.svg"
              />
              <div className="relative leading-[20px] inline-block">
                14-day free trial
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[12px]">
              <img
                className="relative w-[8px] h-[8px] shrink-0"
                alt=""
                src="../ellipse-6571.svg"
              />
              <div className="relative leading-[20px] inline-block">
                No credit check required
              </div>
            </div>
          </div> */}
          <Box
            maxW="492px"
            w="100%"
            zIndex={1}
            className="relative shrink-0 text-4xl text-gray-800 font-helvetica-neue"
          >
            <Image
              left="100px"
              className="absolute top-[13px] w-[184px] h-[50px]"
              alt=""
              src="../vector-60.svg"
              zIndex={-1}
            />
            <Text as="b" className="leading-[64px] inline-block">
              <p className="[margin-block-start:0] [margin-block-end:0px]">
                The global instant payments revolution is here.
              </p>
            </Text>
            <Image
              left="100px"
              className="absolute top-[13.53px] w-[184px] h-[51.47px]"
              alt=""
              src="../vector-59.svg"
            />
          </Box>
          <div className="relative text-base leading-[26px] text-gray-600 inline-block">
            Welcome to the world of one-click, instant settlement payments with
            less than 1% fees on all transactions!
          </div>
          <Flex
            sx={{
              "@media screen and (max-width: 860px)": {
                width: "100%",
              },
            }}
            as="a"
            textDecoration={"none"}
            href="https://pay.enbank.me"
            _hover={{
              bg: "rgb(10, 9, 9, 0.8)",
            }}
            className="cursor-pointer [border:none] p-[14px_40px] bg-gray-800 rounded-[4px] flex flex-row box-border items-center justify-center gap-[16px]"
          >
            <span
              className="relative text-base leading-[26px] font-medium font-inter text-white text-left inline-block"
              color="white"
            >
              Try it now
            </span>
          </Flex>
        </Flex>
      </div>
    </Flex>
  );
};

export default Hero;
