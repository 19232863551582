import { Box, Text, useMediaQuery } from "@chakra-ui/react";
import * as React from "react";
import { FunctionComponent } from "react";
import BankIntegration from "../components/bank-integration";

import Illustration from "../assets/bank-connections-illustration.svg";
import IllustrationVertical from "../assets/bank-connections-illustration-vertical.svg";
import { motion } from "framer-motion";

const Partners = () => {
  const [isMobile] = useMediaQuery("(max-width: 860px)");

  return (
    <Box
      id="partners"
      p="84px 100px"
      boxSizing="border-box"
      sx={{
        "@media screen and (max-width: 860px)": {
          p: "54px calc(max(8px, 50% - 260px))",
        },
      }}
      className="w-full max-w-[1440px] rounded-[16px] bg-white shrink-0 flex flex-col box-border items-center justify-between text-center text-gray-800 font-helvetica-neue"
    >
      <Text
        w="bold"
        fontSize="40px"
        sx={{
          "@media screen and (max-width: 860px)": {
            fontSize: "30px",
          },
        }}
        className="leading-[54px] inline-block"
      >
        Cut fees, not corners
      </Text>

      {isMobile ? (
        <Box
          as={IllustrationVertical}
          className="w-full max-w-[1281px]"
          onClick={() => window.open("https://pay.enbank.me")}
        />
      ) : (
        <Box
          as={Illustration}
          className="w-full max-w-[1281px]"
          onClick={() => window.open("https://pay.enbank.me")}
        />
      )}

      {/* 
      <picture className="w-full max-w-[1281px]">
        <source
          type="image/svg+xml"
          className="w-full max-w-[1281px]"
          media="(min-width: 860px)"
          srcSet="./bank-connections-Illustration.svg"
        />
        <img
          className="w-full max-w-[1281px]"
          src="./bank-connections-illustration-vertical.svg"
        />
      </picture> */}
      <div className="relative text-sm leading-[24px] font-inter text-gray-500 inline-block w-full max-w-[600px]">
        <p className="[margin-block-start:0] [margin-block-end:0px]">
          Skip the card networks and offer your customers the novelty of paying
          directly with their personal bank of choice. Payments arrive straight
          to your bank account. No more waiting for settlement, you can access
          your funds immediately.
        </p>
      </div>
    </Box>
  );
};

export default Partners;
