import { Box, Grid } from "@chakra-ui/react";
import * as React from "react";
import { FunctionComponent } from "react";
import CardPlatform from "../components/card-platform";

const PlatformGrid = () => {
  return (
    <Grid
      gap={4}
      autoRows="324px"
      id="platforms"
      sx={{
        "@media screen and (min-width: 1208px)": {
          width: "1208px",
          gridTemplateColumns: "repeat(3, 1fr)",
          gridTemplateAreas: `"label . banner" ". . banner"`,
        },
        "@media screen and (max-width: 1208px)": {
          width: "804px",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridTemplateAreas: `"label banner" ". banner" ". ."`,
        },
        "@media screen and (max-width: 804px)": {
          gridTemplateColumns: "1fr",
          maxWidth: "400px",
          width: "100%",
          gridTemplateAreas: `"label" "." "." "." "banner"`,
          gridAutoRows: "auto",
        },
      }}
      className="relative text-left text-xl text-gray-800 font-helvetica-neue"
    >
      <Box
        boxSizing="border-box"
        p="4px"
        fontSize="40px"
        lineHeight="54px"
        display="inline-block"
        gridArea="label"
        sx={{
          "@media screen and (max-width: 804px)": {
            fontSize: "30px",
            textAlign: "center",
          },
        }}
      >
        <p className="[margin-block-start:0] [margin-block-end:0px]">{`Platforms we support`}</p>
      </Box>
      <CardPlatform
        title="Mobile"
        iconLMobile="../icon--l--mobile.svg"
        details="No third-party windows. No friction. Integrate our Mobile SDK and reduce your number of abandoned carts"
      />
      <CardPlatform
        title="Web"
        iconLMobile="../icon--l--web.svg"
        details="The easiest payment gateway for your website. Provide your users with the best web checkout experience."
      />
      <CardPlatform
        title="Point of sale"
        iconLMobile="../icon--l--sale.svg"
        details="We are working on the payment revolution for your point of sale terminals. More news coming soon..."
        tag="Coming soon.."
      />
      <Box
        gridArea="banner"
        pos="relative"
        bgImage="../illustration--platform-banner.svg"
        bgColor="rgb(255 184 79)"
        bgRepeat="no-repeat"
        bgPos="bottom 0px left 0px"
        bgSize="107.5% 76.51%"
        rounded="10px"
        textAlign="center"
        sx={{
          "@media screen and (max-width: 804px)": {
            bgImage: "../card-banner-mobile.svg",
            bgPos: "left 8.9% top 8.77%",
            bgSize: "87.51% 52%",
          },
        }}
        h="100%"
        minH={"228px"}
      >
        <Box
          h="100%"
          className="flex flex-col p-[32px] box-border items-center justify-between"
        >
          <div className="flex flex-col items-center justify-center gap-[16px]">
            <div className="relative leading-[30px] font-medium flex items-center justify-center">
              Boost conversion
            </div>
            <div className="relative text-base leading-[26px] font-inter text-gray-600 inline-block">
              The most frictionless checkout experience made for your brand.
            </div>
          </div>
          <Box
            _hover={{
              bg: "rgb(62, 56, 56)",
            }}
            as="button"
            onClick={() => window.open("https://pay.enbank.me")}
            className="cursor-pointer [border:none] p-[14px_40px] bg-gray-800 rounded-[4px] w-full flex flex-row box-border items-center justify-center gap-[16px]"
          >
            <div className="relative text-base leading-[26px] font-medium font-inter text-white text-left inline-block">
              Get started
            </div>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default PlatformGrid;
