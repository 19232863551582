import React, { useState, useCallback } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";

export const useContactInfoSubmit = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [query, setQuery] = useState("");

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
    setEmail(event.target.value);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
    setName(event.target.value);
  };

  const handleQueryChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log(event.target.value);
  }

  const handleSubmit = useCallback(
    async (ev: any) => {
      ev.preventDefault();
      if (!email || !name) {
        return;
      }

      const res = await addToMailchimp(email, { 
        FNAME: name
      });
      return res;
    },
    [email, name]
  );

  return {
    handleEmailChange,
    handleNameChange,
    handleQueryChange,
    handleSubmit,
  };
};
