import { Box } from "@chakra-ui/react";
import * as React from "react";
import { FunctionComponent } from "react";

type CardPlatformType = {
  title?: string;
  iconLMobile?: string;
  details?: string;
  tag?: string;
};

const CardPlatform: FunctionComponent<CardPlatformType> = ({
  title,
  iconLMobile,
  details,
  tag,
}) => {
  return (
    <Box minH={'200px'} className="rounded-[10px] bg-white overflow-hidden flex flex-col p-[32px_40px] box-border items-start justify-between text-left text-xl text-gray-800 font-helvetica-neue">
      <div className="flex w-full flex-col items-start justify-start gap-[8px]">
        <div className="w-full flex flex-row items-center justify-between">
          <div className="relative leading-[30px] font-medium flex items-center shrink-0">
            {title}
          </div>
          <img
            className="relative w-[32px] h-[32px] shrink-0 overflow-hidden"
            alt=""
            src={iconLMobile}
          />
        </div>
        {tag && <div className="rounded-[4px] [border:1px_solid_#a7a6a4] box-border relative flex-row p-[4px_10px] items-center justify-center text-xs text-gray-300 font-inter">
          <div className="relative leading-[20px] inline-block">{tag}</div>
        </div>}
      </div>
      <div className="relative text-base leading-[26px] font-inter text-gray-500 inline-block">
        {details}
      </div>
    </Box>
  );
};

export default CardPlatform;
