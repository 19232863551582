import { Box, Flex, Grid, Image } from "@chakra-ui/react";
import { Link } from "gatsby";
import * as React from "react";
import { FunctionComponent } from "react";

const Footer = ({ contactUs }: { contactUs: () => void }) => {
  return (
    <Grid
      px="57px"
      py="80px"
      justifyItems="center"
      templateColumns="272px 1fr"
      templateRows="28px 1fr"
      gridTemplateAreas={`"logo links" "text links"`}
      rowGap="74px"
      sx={{
        "@media screen and (max-width: 750px)": {
          gridTemplateColumns: "1fr",
          gridTemplateAreas: `"logo" "links" "text"`,
          py: "20px",
          px: "27px",
        },
        "@media screen and (max-width: 480px)": {
          px: "0px",
        },
      }}
      boxSizing="border-box"
      className="w-full max-w-[1440px] relative bg-gray-100 shrink-0 overflow-hidden text-left text-xs text-gray-800 font-inter"
    >
      <Image
        className="w-[99px] h-[28px] object-cover"
        gridArea="logo"
        justifySelf="left"
        alt=""
        src="../image-1867@2x.png"
      />
      <Box
        gridArea="text"
        justifySelf="left"
        w="272px"
        className="leading-[20px] inline-block w-[272px] mix-blend-normal"
      >
        Join the world of one-click, instant settlement payments with less than
        1% fees on all transactions.
      </Box>

      <Flex
        gridArea="links"
        justifyContent="space-between"
        maxW="542px"
        w="100%"
        ml="24px"
        direction="row"
        sx={{
          "@media screen and (max-width: 480px)": {
            flexDir: "column",
            gap: "48px",
          },
        }}
      >
        <div className="flex flex-col items-start justify-start gap-[12px]">
          <div className="relative text-sm leading-[24px] font-medium inline-block">
            Links
          </div>
          <div className="relative leading-[20px] inline-block">
            <a href="#how-it-works">How it works</a>
          </div>
          <div className="relative leading-[20px] inline-block">
            <a href="#integrations">Integrations</a>
          </div>
          <div className="relative leading-[20px] inline-block">
            <a href="#platforms">Platforms</a>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[12px]">
          <div className="relative text-sm leading-[24px] font-medium inline-block">
            Company
          </div>
          {/* <div className="relative leading-[20px] inline-block">About us</div> */}
          <a href="mailto:hello@enbank.me">Contact us</a>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-and-conditions">Terms and Conditions</Link>
        </div>
        <div className="flex flex-col items-start justify-start gap-[16px] text-sm">
          <div className="relative leading-[24px] font-medium inline-block">
            Social media
          </div>
          <div className="flex flex-row items-start justify-start gap-[16px]">
            <a
              href="https://twitter.com/Enbankme"
              className="relative rounded-[50px] w-[24px] h-[24px] shrink-0"
            >
              <div className="absolute h-[141.67%] w-[141.67%] top-[-20.83%] right-[-20.83%] bottom-[-20.83%] left-[-20.83%]" />
              <img
                className="absolute h-[59.66%] w-[72.31%] top-[20.4%] right-[14.52%] bottom-[19.94%] left-[13.17%] max-w-full max-h-full"
                alt=""
                src="../capa-2.svg"
              />
            </a>

            <a href="https://www.linkedin.com/company/enbankme/">
              <img
                className="relative rounded-[50px] w-[24px] h-[24px] shrink-0"
                alt=""
                src="../social-media--linkedin.svg"
              />
            </a>

            <a href="https://www.youtube.com/@enbank4664">
              <img
                className="relative w-[24px] h-[24px] shrink-0"
                alt=""
                src="../social-media--youtube.svg"
              />
            </a>

            <a href="https://www.instagram.com/enbank.me/">
              <Image
                m="4px"
                h="18px"
                w="18px"
                className="relative shrink-0"
                alt=""
                src="../social-media--instagram.svg"
              />
            </a>
          </div>
        </div>
      </Flex>

      {/* <div className="absolute top-[80px] right-[277px] rounded-[4px] bg-white [border:1px_solid_#dad8d5] box-border w-[526px] flex flex-row p-[0px_0px_0px_24px] items-center justify-between text-gray-400">
        <div className="relative leading-[20px] inline-block">Your email</div>
        <div className="rounded-[4px] [border:1px_solid_#0a0909] box-border relative flex flex-row p-[12px_32px] items-center justify-center gap-[16px] text-sm text-gray-800">
          <div className="relative leading-[24px] font-medium inline-block">
            Subscribe
          </div>
        </div>
      </div> */}
      {/* <img
        className="absolute top-[80px] right-[56px] w-[24px] h-[24px] overflow-hidden"
        alt=""
        src="../icon--arrow--long2.svg"
      /> */}

      <Box
        justifySelf="left"
        className="leading-[20px] text-center inline-block opacity-[0.3]"
      >
        © 2023 Enbank. All rights reserved
      </Box>
    </Grid>
  );
};

export default Footer;
