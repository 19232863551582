import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import * as React from "react";
import { FunctionComponent } from "react";

type HandActionItemType = {
  image?: string;
  title?: string;
  title1?: string;
  title2?: string;
};

const HandActionItem: FunctionComponent<HandActionItemType> = ({
  image,
  title,
  title1,
  title2,
}) => {
  return (
    <Box
      as={motion.div}
      initial={{
        scale: 0
      }}
      whileInView={{
        scale: 1
      }}
      viewport={{ once: true }}
      className="flex flex-col items-center justify-start gap-[8px] text-left text-lg text-gray-800 font-helvetica-neue"
    >
      <img
        className="relative w-[158px] h-[158px] shrink-0"
        alt=""
        src={image}
      />
      <div className="relative leading-[28px] font-medium inline-block">
        {title}
      </div>
      <div className="relative text-sm leading-[24px] font-inter text-gray-600 text-center inline-block w-[260px]">
        <p className="[margin-block-start:0] [margin-block-end:0px]">
          {title1}
        </p>
        <p className="m-[0]">{title2}</p>
      </div>
    </Box>
  );
};

export default HandActionItem;
