import { Box, Flex, Grid, Image, Text, useMediaQuery } from "@chakra-ui/react";
import * as React from "react";
import { FunctionComponent, useState } from "react";
import ReactSlider from "react-slider";

const Slider: FunctionComponent = () => {
  const [isLargerThan780] = useMediaQuery("(min-width: 780px)");
  const [isLargerThan570] = useMediaQuery("(min-width: 570px)");
  const [selectedValue, setSelectedValue] = useState(0);

  const prices = [
    "€5,000",
    "€10,000",
    "€50,000",
    "€100,000",
    "€200,000",
    "€300,000",
    "€400,000",
    "€500,000+",
  ];
  const map: Record<string, string> = {
    "€5,000": "€90",
    "€10,000": "€180",
    "€50,000": "€900",
    "€100,000": "€1800",
    "€200,000": "€3600",
    "€300,000": "€" + 3 * 1800,
    "€400,000": "€" + 4 * 1800,
    "€500,000+": "€" + 5 * 1800 + "+",
  };

  const sv = Math.min(selectedValue, prices.length - 1);

  return (
    <Flex
      id="profit"
      maxW="1232px"
      gap="2px"
      w="100%"
      direction="column"
      alignItems="center"
      className="relative shrink-0 text-center text-4xl text-gray-800 font-helvetica-neue"
    >
      <Text
        align="center"
        w="100%"
        className="text-3xl leading-[54px] inline-block"
      >
        Your profit
      </Text>
      <Text
        maxW="620px"
        w="100%"
        mb="64px"
        align="center"
        className="text-base leading-[26px] font-inter text-gray-600 inline-block "
      >
        Tell us how much transaction volume you are processing and let us show
        you how much you could save with us.
      </Text>
      <Box
        boxSizing="border-box"
        p="4px"
        w="calc(100% - 124px)"
        rounded={6}
        bg="white"
      >
        <Box
          h="16px"
          max={prices.length - 1}
          as={ReactSlider}
          value={Math.min(selectedValue, prices.length - 1)}
          onAfterChange={(value: any, index: number) => {
            setSelectedValue(value);
          }}
          renderThumb={(props: any, state: any) => (
            <div {...props}>
              <Box
                w="40px"
                h="40px"
                pos="absolute"
                left="-12px"
                top="-12px"
                cursor="grab"
                className="rounded-[100px] bg-white flex flex-row p-[12px] box-border items-center justify-center"
              >
                <Box
                  w="16px"
                  h="16px"
                  bg="rgb(255, 184, 79)"
                  borderRadius={8}
                ></Box>
              </Box>
              <Box
                w="116px"
                h="50px"
                left="-50px"
                top="50px"
                className="absolute shadow-[0px_4px_20px_-5px_rgba(0,_0,_0,_0.05)] flex flex-col items-center justify-start text-base text-gray-800"
              >
                <img
                  className="relative w-[20px] h-[8px] shrink-0"
                  alt=""
                  src="../vector10.svg"
                />
                <div className="rounded-[4px] bg-white flex flex-col p-[8px_16px] box-border items-start justify-start">
                  <div className="relative leading-[26px] font-medium inline-block w-[85px]">
                    {prices[state.value]}
                  </div>
                </div>
              </Box>
            </div>
          )}
          renderTrack={(
            props: any,
            { index, value }: { index: any; value: any }
          ) => (
            <div {...props}>
              <Box
                w="100%"
                rounded={6}
                h="16px"
                sx={{
                  bg: index == 1 ? "white" : "rgb(255, 184, 79)",
                }}
              />
            </div>
          )}
        />
      </Box>
      {isLargerThan780 && (
        <Flex
          boxSizing="border-box"
          mt="46px"
          pl="48px"
          direction="row"
          justify="space-between"
          w="100%"
        >
          {prices.map((item) => (
            <Box
              fontSize="16px"
              textAlign="center"
              lineHeight="24px"
              fontWeight="500"
              fontFamily="Inter"
              key={item}
            >
              {item}
            </Box>
          ))}
        </Flex>
      )}

      <Grid
        alignItems="center"
        templateColumns="auto 1fr"
        autoFlow="row dense"
        justifyItems="center"
        h="238px"
        templateAreas={`"image title" "image content"`}
        sx={{
          "@media screen and (max-width: 508px)": {
            gridTemplateAreas: `"image title" "content content"`,
            gridTemplateColumns: "1fr 1fr",
            h: "310px"
          },
        }}
        mt="96px"
        maxW="630px"
        w="100%"
        p="24px"
        className="relative rounded-[10px] bg-white text-left"
      >
        <Image
          gridArea="image"
          w="190px"
          h="190px"
          className="max-w-full max-h-full"
          alt=""
          src="../illustration--benefit-big.svg"
        />

        <Text
          gridArea="title"
          as="b"
          className="top-[0px] left-[0px] leading-[64px] inline-block text-center w-full"
        >
          {map[prices[sv] as string]}
        </Text>
        {/* <div className="absolute top-[29px] left-[132px] text-xl leading-[30px] font-medium inline-block">
            Unriveled!
          </div> */}
        <Box
          gridArea="content"
          className="text-sm leading-[24px] font-inter text-gray-500 inline-block w-[274px]"
        >
          You are on your way to saving over 90% of the fees you used to give to
          card processors!
        </Box>
      </Grid>
    </Flex>
  );
};

export default Slider;
