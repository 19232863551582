import { Box, Flex, Image } from "@chakra-ui/react";
import * as React from "react";
import { FunctionComponent } from "react";
import ShopSoonIntegration from "../components/shop-soon-integration";

const Integrations: FunctionComponent = () => {
  return (
    <Flex
      id="integrations"
      direction="column"
      alignItems="center"
      gap="47px"
      sx={{
        "@media screen and (max-width: 630px)": {
          gap: "16px",
        },
      }}
    >
      <b className="relative leading-[54px] inline-block">Plug and Play</b>
      <Box
        maxW="600px"
        w="100%"
        className="relative text-sm leading-[24px] font-inter text-gray-600 inline-block"
      >
        <p className="[margin-block-start:0] [margin-block-end:0px]">
          Integrate with the most popular e-commerce platforms and start
          collecting frictionless low-fee payments in minutes.
        </p>
      </Box>
      <Flex
        w="100%"
        maxW="984px"
        py="16px"
        direction="row"
        boxSizing="border-box"
        align="center"
        justify="space-between"
        sx={{
          "@media screen and (max-width: 910px)": {
            flexDir: "column",
            maxW: '400px',
            gap: "24px"
          },
        }}
      >
        <Image w="100%" src="./integrations-left-group.svg" />
        <Image w="90%" src="./integrations-right-group.svg" />
      </Flex>
    </Flex>
  );
};

export default Integrations;
