import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { motion } from "framer-motion";
import * as React from "react";
import { FunctionComponent } from "react";
import BankIntegration from "./bank-integration";

const ShowcaseVideo = () => {
  const [isMobile] = useMediaQuery("(max-width: 860px)");

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      id="how-it-works"
      p="42px 100px"
      boxSizing="border-box"
      sx={{
        "@media screen and (max-width: 860px)": {
          p: "54px calc(max(8px, 50% - 260px))",
        },
      }}
      as={motion.div}
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      viewport={{ once: true }}
    >
      <Text
        w="bold"
        fontSize="40px"
        sx={{
          "@media screen and (max-width: 860px)": {
            fontSize: "30px",
          },
        }}
        pb={8}
        className="leading-[54px] inline-block"
      >
        How it works
      </Text>

      <Box
        w="80vw"
        h="45vw"
        as="iframe"
        sx={{
          "@media screen and (max-width: 650px)": {
            w: "95vw",
            h: "150vw",
          },
        }}
        src="https://www.youtube-nocookie.com/embed/298f78iBQ9k"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
      ></Box>
    </Flex>
  );
};

export default ShowcaseVideo;
