import { Box, Flex, Image } from "@chakra-ui/react";
import * as React from "react";
import { FunctionComponent } from "react";

const GetStartedBanner = () => {
  return (
    <Flex
      boxSizing="border-box"
      id="get-started"
      sx={{
        "@media screen and (min-width: 860px)": {
          h: "516px",
          p: "120px",
        },
      }}
      p="32px"
      className="w-full max-w-[1440px] relative rounded-[16px] bg-orange shrink-0 overflow-hidden text-center text-4xl text-gray-800 font-helvetica-neue"
    >
      <img
        className="absolute top-[100px] right-[720px] xl:right-[61.805%] w-[430px] h-[508px] overflow-hidden"
        alt=""
        src="../illustration--bottom-banner.svg"
      />
      <Flex
        gap="35px"
        direction="column"
        className="mx-auto lg:right-[120px] lg:absolute relative w-[430px]"
      >
        <b className="relative leading-[64px] inline-block">
          Get started now{" "}
          <Image
            h="6px"
            w="110px"
            left="69%"
            sx={{
              "@media screen and (max-width: 508px)": {
                left: "32%",
              },
            }}
            className="absolute max-w-full max-h-full"
            alt=""
            src="../vector17.svg"
          />
        </b>

        <div className="text-base leading-[26px] font-inter text-gray-600 text-left inline-block mix-blend-normal">
          <p className="[margin-block-start:0] [margin-block-end:0px]">
            Join the payment revolution now and let us help you move money at
            lightning speed, wherever you are.
          </p>
        </div>

        <Flex
          sx={{
            "@media screen and (max-width: 860px)": {
              width: "100%",
            },
          }}
          w="180px"
          as="button"
          onClick={() => window.open("https://pay.enbank.me")}
          className="cursor-pointer [border:none] p-[0] bg-[transparent] flex flex-row items-center justify-start"
        >
          <Box
            _hover={{
              bg: "rgb(62, 56, 56)",
            }}
            className="w-full cursor-pointer [border:none] p-[14px_40px] bg-gray-800 rounded-[4px] flex flex-row box-border items-center justify-center gap-[16px]"
          >
            <div className="relative text-base leading-[26px] font-medium font-inter text-white text-left inline-block">
              Get started
            </div>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default GetStartedBanner;
